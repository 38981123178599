.hero-right-line::after {
  position: absolute;
  content: url(../assets/img/line2.svg);
  bottom: -55%;
  left: -15%;
  z-index: -1;
}

@media screen and (min-width: 768px) {
  .hero-right-line::after {
    bottom: -75%;
  }
}

@media screen and (min-width: 1024px) {
  .hero-right-line::after {
    left: 38%;
    bottom: -55%;
  }
}

@media screen and (min-width: 1280px) {
  .hero-right-line::after {
    left: 25%;
    bottom: -50%;
  }
}

.hero-left-line::after {
  position: absolute;
  content: url(../assets/img/line3.svg);
  bottom: -55%;
  right: -15%;
  z-index: -1;
}

@media screen and (min-width: 768px) {
  .hero-left-line::after {
    bottom: -75%;
  }
}

@media screen and (min-width: 1024px) {
  .hero-left-line::after {
    right: 38%;
    bottom: -55%;
  }
}

@media screen and (min-width: 1280px) {
  .hero-left-line::after {
    right: 25%;
    bottom: -50%;
  }
}

.hero-filer-line::after {
  position: absolute;
  content: url(../assets/img/line5.svg);
  bottom: -35%;
  left: 5%;
  z-index: -1;
}

.title-hero {
  position: relative;
}

.title-hero::after {
  position: absolute;
  content: url(../assets/img/frame.svg);
  left: -12%;
  top: -42%;
}

@media screen and (min-width: 768px) {
  .title-hero::after {
    left: -5%;
    top: -45%;
  }
}

.add-list-banner {
  position: relative;
}

.add-list-banner::before {
  position: absolute;
  content: url(../assets/img/line5.svg);
  top: -18%;
  right: 24%;
  z-index: -1;
}

@media screen and (min-width: 475px) {
  .add-list-banner::before {
    top: -16%;
    right: 29%;
  }
}

@media screen and (min-width: 640px) {
  .add-list-banner::before {
    top: -14%;
    right: 35%;
  }
}

@media screen and (min-width: 768px) {
  .add-list-banner::before {
    top: -34%;
    right: 50%;
  }
}

@media screen and (min-width: 1024px) {
  .add-list-banner::before {
    content: url(../assets/img/line5md.svg);
    top: -30%;
    right: 50%;
  }
}

.add-list-banner::after {
  position: absolute;
  content: url(../assets/img/line1.svg);
  bottom: -18%;
  right: 24%;
  z-index: -1;
}

@media screen and (min-width: 475px) {
  .add-list-banner::after {
    bottom: -19%;
    right: 29%;
  }
}

@media screen and (min-width: 640px) {
  .add-list-banner::after {
    bottom: -17%;
    right: 35%;
  }
}

@media screen and (min-width: 768px) {
  .add-list-banner::after {
    bottom: -42%;
    right: 50%;
  }
}

@media screen and (min-width: 1024px) {
  .add-list-banner::after {
    content: url(../assets/img/line1md.svg);
    bottom: -34%;
  }
}

@media screen and (min-width: 1400px) {
  .add-list-banner::after {
    right: 47%;
  }
}

.find-property-line {
  position: relative;
}

.find-property-line::before {
  position: absolute;
  content: url(../assets/img/line1.svg);
  left: 17%;
  top: -15px;
  z-index: -1;
}

@media screen and (min-width: 768px) {
  .find-property-line::before {
    content: url(../assets/img/line1md.svg);
    left: 14%;
    top: -30px;
  }
}

.find-property:nth-child(3) {
  width: 100%;
}

.subLayerFilter {
  top: 15rem;
}

@media screen and (min-width: 368px) {
  .find-property:nth-child(3) {
    width: auto;
  }
  .subLayerFilter {
    top: 7rem;
  }
}

.line7 {
  position: relative;
}

.line7::after {
  position: absolute;
  content: url(../assets/img/line7.svg);
  top: 55%;
  left: 15%;
  z-index: -1;
}

@media screen and (min-width: 460px) {
  .line7::after {
    top: 64%;
    left: 18%;
  }
}

@media screen and (min-width: 600px) {
  .line7::after {
    top: 70%;
    left: 20%;
  }
}

@media screen and (min-width: 768px) {
  .line7::after {
    content: url(../assets/img/line7md.svg);
    left: -30%;
    top: 59%;
  }
}

@media screen and (min-width: 900px) {
  .line7::after {
    left: -20%;
    top: 65%;
  }
}

@media screen and (min-width: 1024px) {
  .line7::after {
    content: url(../assets/img/line7lg.svg);
    left: -58%;
    top: 45%;
  }
}

@media screen and (min-width: 1100px) {
  .line7::after {
    left: -49%;
    top: 49%;
  }
}

@media screen and (min-width: 1440px) {
  .line7::after {
    left: -35%;
    top: 55%;
  }
}

@media screen and (min-width: 1600px) {
  .line7::after {
    left: -55%;
  }
}
.line8 {
  position: relative;
}

.line8::after {
  position: absolute;
  content: url(../assets/img/line8.svg);
  left: 25%;
  z-index: -1;
}

@media screen and (min-width: 768px) {
  .line8::after {
    content: url(../assets/img/line8md.svg);
    left: 48%;
    top: 90%;
  }
}

@media screen and (min-width: 1024px) {
  .line8::after {
    content: url(../assets/img/line8lg.svg);
    top: 82%;
    left: 45%;
  }
}

.line9 {
  position: relative;
}

@media screen and (min-width: 1024px) {
  .line9::after {
    position: absolute;
    content: url(../assets/img/line9lg.svg);
    left: 15%;
    bottom: -35%;
    z-index: -1;
  }
}

@media screen and (min-width: 1280px) {
  .line9::after {
    left: 25%;
  }
}
