.btn {
  border: 1px solid;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  position: relative;
}

/* .variant--disable:hover .tooltip {
   display: block;
} */

.tooltip {
  position: absolute;
  right: 0;
  left: 0;
  visibility: hidden;
}

.btn:hover .tooltip {
  visibility: visible;
}

.tooltip::after {
  position: absolute;
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 8px solid #006ffd;
  right: calc(50% - 4px);
}

.tooltip--top {
  bottom: calc(100% + 10px);
}

.tooltip-inner {
  background: #006ffd;
  max-width: 350px;
  padding: 12px;
  border-radius: 12px;
  margin: 0 auto;
  display: inline-block;
  font-weight: 400;
}

a.btn {
  display: inline-block;
}

.medium {
  padding: 12px;
  border-radius: 8px;
  font-size: 12px;
  line-height: 10px;
}

.large {
  padding: 8px 15px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 25px;
}

.btn--inner {
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .large {
    font-size: 13px;
    padding: 8px 12px;
    line-height: 18px;
    font-weight: 500;
    border-radius: 8px;
  }

  .medium {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    border-radius: 12px;
    padding: 8px 12px;
  }
}

@media screen and (min-width: 1280px) {
  .large {
    font-size: 16px;
    font-weight: 500;
    padding: 12px 20px;
    border-radius: 12px;
    line-height: 20px;
  }

  .medium {
    font-size: 12px;
  }
}

.btn svg {
  transition: all 0.3s ease;
}

.variant--primary-fill,
.variant--primary {
  background-color: #006ffd !important;
  color: #fff;
  border-color: #006ffd;
}

.variant--primary-fill:hover,
.variant--primary:hover {
  background-color: #eaf2ff !important;
  color: #006ffd;
}

.variant--outLine-stroke,
.variant--outLine {
  border-color: #006ffd;
  color: #006ffd;
  fill: transparent;
}

.variant--outLine-danger {
  border-color: #f14b4b;
  color: #f14b4b;
}

.variant--danger {
  background-color: #f14b4b;
  color: #fff;
  border-color: #f14b4b;
}

.variant--danger:hover {
  background-color: transparent;
  color: #f14b4b;
}

.variant--success {
  background-color: #6fcf97;
  color: #fff !important;
}

.variant--outLine-success {
  border-color: #6fcf97;
  color: #6fcf97;
}

.variant--outLine-warning {
  border-color: #f2c94c;
  color: #f2c94c;
}
.variant--text-warning {
  color: #f2c94c;
  border: none;
  background: none;
}
.variant--outLine-stroke:hover,
.variant--outLine:hover {
  background-color: #eaf2ff;
}

.variant--outLine-stroke {
  stroke: #006ffd;
}

.variant--text-fill,
.variant--text {
  border: none;
  background: none;
  color: #006ffd;
}

.variant--disable {
  cursor: default !important;
  background: #cccccc;
  border: 0;
  color: #fff;
}

.variant--outLine-disable {
  cursor: default !important;
  border-color: #929292;
  color: #929292;
}

.loading {
  position: absolute;
  left: 12px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  top: calc(50% - 12.5px);
  -webkit-animation: spin 0.4s linear infinite;
  /* Safari */
  animation: spin 0.4s linear infinite;
}

.loading--primary-fill,
.loading--primary {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #006ffd;
  border-left: 2px solid #006ffd;
}

.loading--outLine,
.loading--outLine-stroke,
.loading--text {
  border: 2px solid #006ffd;
  border-top: 2px solid #f3f3f3;
  border-left: 2px solid #f3f3f3;
}

.loading--outLine-danger {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #f14b4b !important;
  border-left: 2px solid #f14b4b !important;
}

.loading--danger {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #f14b4b !important;
  border-left: 2px solid #f14b4b !important;
}

.loading--success {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #6fcf97;
  border-left: 2px solid #6fcf97;
}

.loadingClassName {
  margin-left: 30px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.icon-size {
  font-size: 19px;
}
@media (min-width: 768px) {
  .icon-size {
    font-size: 15px;
  }
}
@media (min-width: 1280px) {
  .icon-size {
    font-size: 19px;
  }
}
