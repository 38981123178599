.modal {
  background-color: #fff;
  padding: 16px;
  border-radius: 16px;
}

@media screen and (min-width: 640px) {
  .modal {
    border-radius: 16px;
  }
}

.modal-header {
  padding-bottom: 16px;
}
