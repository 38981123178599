/* default color is gray2 */

.variant-h1 {
  font-weight: 900;
  font-size: 24px;
  line-height: 35px;
  color: #23274d;
}

.variant-h2 {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #23274d;
}

.variant-h3 {
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: #23274d;
}

.variant-h4 {
  color: #23274d;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
}

.variant-p1 {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #929292;
}

.variant-p2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #929292;
}

.variant-p3 {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #929292;
}

.variant-p4 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #929292;
}

.variant-p5 {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #929292;
}

.variant-h4-card {
  color: #23274d;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
}

.variant-h5-card {
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
}

.variant-p6 {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #929292;
}

@media screen and (min-width: 768px) {
  .variant-h3 {
    font-size: 22px;
    line-height: 29px;
  }

  /* .variant-p3 {
        font-weight: 500;
        font-size: 10px;
    } */

  /* .variant-h4-card {
        font-size: 12px;
        line-height: 15px;
    } */

  .variant-h1 {
    font-size: 30px;
    line-height: 40px;
  }

  .variant-h2 {
    font-size: 26px;
    line-height: 32px;
  }

  .variant-p2 {
    font-size: 12px;
    line-height: 18px;
  }

  .variant-p1 {
    font-size: 16px;
  }

  .variant-p4 {
    font-size: 10px;
    line-height: 14px;
  }

  .variant-p5 {
    line-height: 20px;
    font-size: 10px;
  }

  .variant-p6 {
    font-weight: 400;
    font-size: 11px;
  }

  .variant-h5-card {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (min-width: 1280px) {
  .variant-h3 {
    font-size: 32px;
    line-height: 42px;
  }

  .variant-p3 {
    font-size: 16px;
    line-height: 28px;
  }

  .variant-h4-card {
    font-size: 20px;
    line-height: 24px;
  }

  .variant-h1 {
    font-size: 50px;
    line-height: 80px;
  }

  .variant-h2 {
    font-size: 42px;
    line-height: 46px;
  }

  .variant-p2 {
    font-size: 17px;
    line-height: 27px;
  }

  .variant-p1 {
    font-size: 18px;
    line-height: 42px;
  }

  .variant-p4 {
    font-size: 14px;
    line-height: 25px;
  }

  .variant-h4 {
    font-size: 30px;
    line-height: 39px;
  }

  .variant-p5 {
    line-height: 20px;
    font-size: 12px;
  }

  .variant-p6 {
    font-size: 14px;
    line-height: 25px;
  }

  .variant-h5-card {
    font-size: 22px;
    /* line-height: 28px; */
    line-height: 38px;
  }
}

.color-primary {
  color: #006ffd;
}

.color-gray2 {
  color: #929292;
}

.color-gray1 {
  color: #636464;
}

.color-gray4 {
  color: #d1d6dd;
}

.color-black {
  color: #23274d;
}

.color-white {
  color: #fff;
}

.color-warning {
  color: #f2c94c;
}

.color-success {
  color: #6fcf97;
}

.color-danger {
  color: #f14b4b;
}
