.spin-loading {
  border: 2px solid #f3f3f3;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-animation: spin 0.5s linear infinite;
  /* Safari */
  animation: spin 0.4s linear infinite;
}

 .containerLoading {
  background: #000;
  opacity: 0.5;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.item-primary {
  border-top: 2px solid #006ffd;
  border-left: 2px solid #006ffd;
}

.item-gray {
  border-top: 2px solid #b3bcc7;
  border-left: 2px solid #b3bcc7;
}
