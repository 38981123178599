.variantContainer--standard {
  position: relative;
  margin-top: 16px;
}

.variantDiv--standard {
  border-radius: 12px;
  padding: 0px 16px;
  border: 1px solid #d1d6dd;
  background: #f7f8f9;
  height: 49px;
  display: flex;
  align-items: center;
}

.variantDiv--standard:focus-within {
  border: 1px solid #006ffd;
}

.variantInput--standard {
  background: transparent;
  width: 100%;
  height: 100%;
  color: #636464;
  display: flex;
  flex: 1;
}

.variantDivTextarea--standard {
  height: 100px;
}

.variantInput--standard::placeholder {
  color: #d1d6dd !important;
}

.variantErrorDiv--standard {
  border-color: #f14b4b;
}

.variantErrorInput--standard {
  color: #f14b4b;
  padding-top: 4px;
  font-size: 12px;
}

.variantLable--standard {
  padding-bottom: 4px;
  font-size: 14px;
  line-height: 20px;
  color: #23274d;
  font-weight: 400;
  text-transform: capitalize;
  display: block;
}

@media screen and (min-width: 768px) {
  .variantDiv--standard {
    padding: 0px 8px;
    border-radius: 8px;
    height: 42px;
  }

  .variantLable--standard {
    font-size: 12px;
    line-height: 14px;
  }

  .variantDivTextarea--standard {
    height: 70px;
  }
}

@media screen and (min-width: 1280px) {
  .variantDiv--standard {
    padding: 0px 16px;
    border-radius: 12px;
    height: 49px;
  }

  .variantLable--standard {
    font-size: 14px;
    line-height: 28px;
  }

  .variantDivTextarea--standard {
    height: 103px;
  }
}

.parent-select {
  display: flex;
  align-items: center;
}
.datePicker-select-wrapper {
  position: relative;
}
.datePicker-select-wrapper::after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #006ffd;
  bottom: 4px;
  right: 3px;
  transform: rotate(-45deg);
}

.datePicker-select {
  text-align: center;
  outline: none;
  margin-top: 5px;
  padding: 3px 10px;
  border: 1px solid #d1d6dd;
  border-radius: 3px;
  font-weight: 600;
  font-size: 1.4rem;
  cursor: pointer;
  user-select: none;
  appearance: none;
  /* Remove default appearance */
  -webkit-appearance: none;
  /* For Safari */
  -moz-appearance: none;
  /* For Firefox */
}

.select-single-items {
  max-height: 30rem;
  overflow: auto;
  position: absolute;
  left: 0;
  top: 105%;
  background: white;
  padding: 0 16px;
  border-radius: 8px;
  width: 100%;
  z-index: 40;
}
