.avatar {
  /* border-radius: 50%; */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container-avatar {
  position: relative;
  display: inline-block;
}

.wrapper-avatar {
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-color: #e1ecfb;
  border-radius: 50%;
  overflow: hidden;
}

.label--primary::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 20px;
  bottom: 0;
  background: #006ffd;
}

.label--blue20::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 20px;
  bottom: 0;
  background: #b8d4f6;
}

.size--medium {
  width: 5rem;
  height: 5rem;
}

.size--small {
  width: 3rem;
  height: 3rem;
}

@media (min-width: 767px) {
  .size--medium {
    width: 4rem;
    height: 4rem;
  }
}

@media (min-width: 1280px) {
  .size--medium {
    width: 6rem;
    height: 6rem;
  }
}

/* .label--primary {
    background-color: #006FFD;
    color: white;
    width: 100%;
    height: 10px;
    border-radius: 0 0 50% 50%;
} */