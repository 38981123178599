.topic:hover svg {
  color: #006ffd !important;
  transition: all 0.3s ease;
}

.topic {
  transition: all 0.3s ease;
}

.topic:hover {
  background: #eaf2ff !important;
}

.Benefit {
  max-width: 27rem;
  margin-left: 12px;
  margin-right: 12px;
}

.Benefit img {
  width: 40px;
  height: 44px;
}

@media screen and (min-width: 768px) {
  .Benefit img {
    width: 26px;
    height: 29px;
  }
}

@media screen and (min-width: 1280px) {
  .Benefit img {
    width: 57px;
    height: 62px;
  }
}

.link-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  padding: 17px 22px;
}

@media screen and (min-width: 768px) {
  .link-box {
    border-radius: 8px;
    padding: 12px 11px;
  }
}

@media screen and (min-width: 1280px) {
  .link-box {
    border-radius: 12px;
    padding: 24px 20px;
  }
}

.plan {
  min-height: 367px;
}

@media screen and (min-width: 768px) {
  .plan {
    width: 280px;
  }
}

@media screen and (min-width: 1280px) {
  .plan {
    width: 360px;
  }

  .plan {
    min-height: 476px;
  }
}

.plan-variant-popular {
  position: relative;
  border-color: #006ffd !important;
  transform: scaleY(1.04);
}

.plan-variant-popular::after {
  position: absolute;
  content: url(../assets/popular.svg);
  right: 29px;
  top: -18px;
}

@media screen and (min-width: 768px) {
  .plan-variant-popular::after {
    content: url(../assets/popular@2x.svg);
    right: 5px;
    top: -13px;
  }
}

@media screen and (min-width: 800px) {
  .plan-variant-popular::after {
    right: 20px;
  }
}

@media screen and (min-width: 1000px) {
  .plan-variant-popular::after {
    right: 50px;
  }
}

@media screen and (min-width: 1280px) {
  .plan-variant-popular::after {
    content: url(../assets/popular@3x.svg);
    right: 30px;
    top: -25px;
  }
}

@media screen and (min-width: 1440px) {
  .plan-variant-popular::after {
    right: 50px;
  }
}

.introduction-title {
  text-transform: capitalize;
}

.verification-card {
  margin: 32px 0px;
}

.feature-label {
  font-size: 1.5rem;
}

.bankCard {
  box-shadow: 20px 20px 50px 0px rgba(37, 73, 211, 0.1);
}
.bankCard-header {
  position: relative;
}

.bankCard-header::after {
  position: absolute;
  content: url('../assets/bank-card-line.svg');
  top: 0;
  left: 3.2rem;
  z-index: 0;
}

@media (min-width: 1280px) {
  .bankCard-header::after {
    content: url('../assets/bank-card-line@3x.svg');
  }
}

@media (min-width: 768px) {
  .bankCard-footer {
    position: relative;
  }

  .bankCard-footer::after {
    position: relative;
    position: absolute;
    content: url('../assets/double-circle.svg');
    top: calc(50% - 12px);
    right: 2.4rem;
  }
}

.uploadCard-container {
  @apply py-[6.7rem] px-8;
}
