.variant--input {
  border-radius: 12px;
  padding: 0px 16px;
  height: 49px;
  margin-bottom: 16px;
}

.variant--textarea {
  border-radius: 12px;
  padding: 0px 16px;
  height: 49px;
  margin-bottom: 16px;
  height: 150px;
}
